.prefooterNewsletterForm {
	composes: g-grid-container from global;
	align-items: center;
	padding-bottom: var(--hdsplus-spacing-07);
	padding-top: var(--hdsplus-spacing-07);

	@media (--large) {
		padding-bottom: var(--hdsplus-spacing-10);
		padding-top: var(--hdsplus-spacing-10);
		grid-template-columns: 2fr 1fr;
		column-gap: var(--hdsplus-spacing-10);
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-05);

	@media (--medium-up) {
		gap: var(--hdsplus-spacing-01);
	}
}